import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import { Link } from 'gatsby'

import { TestimonialVideo } from '../components/testimonial_video'
import ContactForm from '../components/estimate-form'
import Breadcrumbs from '../components/breadcrumbs'
import { StartWithAFreeQuote } from '../components/start-with-a-free-quote'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2.5em;
`

const Heading = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2.5em 1em;
  padding-bottom: 0.9em;
  text-align: center;
  h1 {
    font-size: calc(1.35vw + 1.5em);
    margin-bottom: 0.15em;
  }
  p {
    margin-bottom: 0.15em;
  }
`

const TestimonialsPage = (props) => {
  let { data } = props

  return (
    <>
      <Layout pageProps={props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/testimonials', label: 'Testimonials' },
                {
                  to: props.location.pathname,
                  label: props.data.pageName.name,
                },
              ]}
            />
          </nav>
        </div>

        <SEO
          title={'Testimonials | Atlas Stucco'}
          description={
            'Atlas Stucco Blog Offers Industry News, Home Improvement Tips, Ideas, Insights and More!'
          }
        />
        <Heading>
          <h1>Project Videos</h1>
          <p>Check out some of our recently completed projects.</p>
        </Heading>
        <div className="container mx-auto text-center py-2">
          {[
            {
              node: {
                id: '1-all-items',
                name: 'All',
                slug: '',
              },
            },
            ...data.allContentfulTestimonialCategory.edges,
          ].map(({ node: { id, name, slug } }) => (
            <Link to={'/testimonials/' + slug} className="p-2">
              {name}
            </Link>
          ))}
        </div>
        <BlogPostsLayoutContainer className="container pt-2">
          {data.allContentfulTestimonialVideoPost.edges.map(
            ({ node: testimonialVideo }, i) => (
              <TestimonialVideo
                columnsClassName="col-lg-4"
                {...testimonialVideo}
                showTitle
              />
            )
          )}
        </BlogPostsLayoutContainer>
        <StartWithAFreeQuote {...props} data={data} />

        <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
      </Layout>
    </>
  )
}

export default TestimonialsPage

export const pageQuery = graphql`
  query getAllTestimonialVideosByCategory($slug: String!) {
    allContentfulTestimonialCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    pageName: contentfulTestimonialCategory(slug: { eq: $slug }) {
      name
    }
    allContentfulTestimonialVideoPost(
      filter: { category: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          slug
          createdAt
          category {
            name
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          projectReference {
            slug
          }
          thumbnail {
            id
            file {
              url
            }
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
    }
    contactFormSideImage: contentfulAsset(
      title: { eq: "Bathroom Remodeling Sacramento" }
    ) {
      id
      title
      gatsbyImageData
    }
  }
`
